body {
  padding: 1em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

form {
  width: 250px;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
}

.input-list {
  width: 90%;
}

input {
  padding: .5em;
}

.input-add {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.button-wrapper {
  margin-top: 1em;
}

button {
  border: 0;
  padding: .5em;
  border-radius: .5em;
  color: #1d1d1d;
}

.button--cta {
  padding: .5em 1em;
  background: #00c56c;
  font-size: 1em
}

ul {
  padding-left: 0;
  list-style-type: none;
}

canvas.ready {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="120" height="22" style="font-size:18px"><text y="18">▶️ Draai!</text></svg>'), auto;
}

.hidden {
  display: none;
}